import React from 'react';
import './Selection.css'


function Selection({ text, active, press }) {

    return (
        <div 
            class="selection-button"
            style={active ? 
                {borderColor: "#9D7AFF", color: "#9D7AFF", fontWeight: 'bold'} 
                : null
            }
            onClick={text == "songs" ? () => press(true) : () => press(false) }
        >
            {text}
        </div>
    );
  }
  
  export default Selection;