import React, { useEffect, useState } from 'react';
import './App.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';
import logo from './assets/logo_v2.png';
import {SignInUser, LogoutUser} from './functions/AuthFunctions';
import {searchSpotifySongs, getSpotifySongData, searchSpotifyPodcasts} from './functions/SpotifyFunctions';
import {getAppleMusicSongs, getAppleMusicPodcasts} from './functions/AppleFunctions';
import Track from './components/Track';
import Pod from './components/Pod';
import Bar from './components/Bar';
import Signin from './components/Signin';
import Selection from './components/Selection';
import Mapbox from './components/Mapbox';
import Error from './components/Error';
import About from './components/About';
import ReactGA from 'react-ga';
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getDatabase, ref, set } from "firebase/database";
import { getAnalytics, logEvent } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC8q4KWo5_zDyX_4ZhYt5oktbj67wawTLY",
  authDomain: "qhacks-9b14d.firebaseapp.com",
  databaseURL: "https://qhacks-9b14d-default-rtdb.firebaseio.com",
  projectId: "qhacks-9b14d",
  storageBucket: "qhacks-9b14d.appspot.com",
  messagingSenderId: "407275551530",
  appId: "1:407275551530:web:8a27b960c7eac635387c53",
  measurementId: "G-7KSLE7KM0R"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);
const database = getDatabase(app);
const analytics = getAnalytics();


const TRACKING_ID = "G-XEHMTVNM17"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;


function App() {
  const [place, setPlace] = useState("");
  const CLIENT_ID = "3dfbc3cfd63b423d98a8222d57e32665"
  const REDIRECT_URI = "https://charts.global/"
  const AUTH_ENDPOINT = "https://accounts.spotify.com/authorize"
  const RESPONSE_TYPE = "token"
  const [token, setToken] = useState("")
  const [useSpotify, setUseSpotify] = useState(true);
  const [tracks, setTracks] = useState([]);
  const [pods, setPods] = useState([]);
  const [seeSongs, setSeeSongs] = useState(true);
  const [error, setError] = useState(false);
  const [seeAbout, setSeeAbout] = useState(false);

  // 
  useEffect(() => {
    window.localStorage.removeItem("token")

    var token = SignInUser(); // call to functions/AuthFunctions
    setToken(token)
    setPlace("United Kingdom")
    
    
    if ((token !== "" && token) || (!useSpotify)) {
      getSpotifyOrApple("United Kingdom", useSpotify, token)
    }

  }, [])

  // Logout function - Calls functions/AuthFunctions/LogoutUser to log the user out
  // Set the track and user token both to empty
  const logout = () => {
    try{
      LogoutUser() // call to functions/AuthFunctions
      setTracks([])
      setToken("")
    }
    catch(e) {
      alert("sum ting bad happen")
      
       // Logging Errors
       var today = new Date().toLocaleString()
       const newDate = today.replaceAll('/', '-');    
       var errorMessage = e.replaceAll('/', '-')
       set(ref(database, 'errors/' + newDate), {
         error : errorMessage,
       });
    }
  }

  // initialClick - Gets called after user selects their login method (Spotify or Apple Music)
  // It loads the initial data from England into the program
  const initialClick = (spotifyOrNah) => {
    
      if (spotifyOrNah) {
        setUseSpotify(true)
        getSpotifyOrApple("United Kingdom", true, token)
      }
      else{
        setUseSpotify(false)
        getSpotifyOrApple("United Kingdom", false, token)
      }
    
  
  }

  // getSpotifyOrApple - Gets called every time a user selects a location on the map
  const getSpotifyOrApple = async (local_location, spotifyOrNah, token) => {
    
    try {
  
      setPlace(local_location);

      if (spotifyOrNah){
      
        

        var token = SignInUser(); // call to functions/AuthFunctions
        setToken(token);
        searchSongsSpotify(local_location, token);
      }
      else {
       
        
        getAppleMusicData(local_location)
      }
    }
    catch(e) {
      alert("Something went wrong")
      setTracks([])
      setPods([])

      // Logging Errors
      var today = new Date().toLocaleString()
      const newDate = today.replaceAll('/', '-');    
      var errorMessage = e.replaceAll('/', '-')
      set(ref(database, 'errors/' + newDate), {
        error : errorMessage,
      });
    }
  
  }


  const searchSongsSpotify = async (location, myToken) => {
      setPlace(location)
      var playlist_url = await searchSpotifySongs (location, myToken, setError) // call to functions/SpotifyFunctions
      getPodcastsSpotify(location, myToken)
      getDataSpotify(playlist_url, myToken)
 
  }
  

  const getPodcastsSpotify = async (location, myToken) => {
    

      var result = await searchSpotifyPodcasts(location, myToken, setError)
      setPods(result)
 

  }

  const getDataSpotify = async (query, myToken) => {

 
      var result = await getSpotifySongData(query, myToken, setError)
      setTracks(result)
   
  }

  
  async function getAppleMusicData(location) {

    
      var song_result = await getAppleMusicSongs(location, setError)
      var pod_result = await getAppleMusicPodcasts(location, setError)

      setPods(pod_result)

      setTracks(song_result)
 
  }



  return (
    <div>
      <div class="info-box">
        <div class="title"><img width={"80%"} src={logo}/></div>
        <div class="subtitle">Discover trending content from around the world.</div>
        <div class="selection-buttons">

          <Selection text="songs" active={seeSongs} press={setSeeSongs}/>
          <Selection text="podcasts" active={!seeSongs} press={setSeeSongs}/>
          
        </div>
        <div class="tracks-header">
          {seeSongs ? "tracks" : "podcasts"} from <span style={{fontWeight: 'bold'}}>{place}:</span>
        </div>

        { seeSongs ?

        tracks.length ? 
          useSpotify ?
            tracks.map((track, id) =>  // SPOTIFY TRACK DATA
              <Track
                key={id} 
                num={id}
                title={track.name}
                artists={
                  track.artists ? track.artists : {name : "No Artist"}
                }
                year={
                  track.album.release_date ? track.album.release_date.slice(0, 4) : "No Year"
                }
                album={track.album.images.length ? track.album.images[0].url : "https://imageio.forbes.com/specials-images/imageserve/5ed6636cdd5d320006caf841/The-Blackout-Tuesday-movement-is-causing-Instagram-feeds-to-turn-black-/960x0.jpg?fit=bounds&format=jpg&width=960"}
                link={track.external_urls.spotify}
                type={"Spotify"}
              />
            )
          :
            tracks.map((track, id) =>  // APPLE MUSIC TRACK DATA
              <Track
                key={id} 
                num={id}
                title={track.attributes.name}
                artists={
                  track.attributes.artistName ? track.attributes.artistName : "No Artist"
                }
                year={track.attributes.releaseDate ? track.attributes.releaseDate.slice(0,4) : "No Year"}
                album={track.attributes.artwork.url.replace('{w}x{h}', '300x300')}
                link={track.attributes.url}
                type={"Apple"}
              />
            )
        :
        <div style={{
          fontSize: '16pt'
        }}>
          no songs found :(
        </div>
        
        :
        
        pods.length ? 
          useSpotify ?
            pods.map((ep, id) =>  // SPOTIFY PODCASTS
              <Pod 
                key={id}
                num={id}
                title={ep.name}
                album={ep.images[0].url}
                link={ep.external_urls.spotify}
                type="Spotify"
              />
            )
          :
            pods.map((ep, id) => // APPLE PODCASTS
              <Pod 
                key={id}
                num={id}
                title={ep.trackName}
                album={ep.artworkUrl100}
                link={ep.trackViewUrl}
                type="Apple"
              /> 
            )
        :
        <div style={{
          fontSize: '16pt'
        }}>
          no podcasts found :(
        </div>

        }

      </div>

      <Mapbox useSpotify ={useSpotify}onDrag = {getSpotifyOrApple} token={token} />
      
      { seeAbout ?
        <About showAbout={setSeeAbout} /> : null
      }

      <Bar about={seeAbout} showAbout={setSeeAbout} logout={logout} />


      { (token || !useSpotify || tracks==[]) ? null : 
        <Signin 
          clicked={`${AUTH_ENDPOINT}?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=${RESPONSE_TYPE}`}
          platform={setUseSpotify}
          onClick = {initialClick}
          
        /> 
      }

      { error ? <Error /> : null }

    </div>
  );
  
  
}

export default App;
