import React from 'react';
import './Signin.css'

import logo from '../assets/logo_v2.png';
import SpotifyLogo from '../assets/Spotify_logo_blk.svg';
import AppleLogo from '../assets/Apple_Music_logo.svg';

function Signin({ clicked, platform, onClick }) {


    async function setType(platform_type){
        platform(platform_type)
        onClick(platform_type)

    }

    return (
        <div style={{position: 'fixed', width: '100vw', height: '100vh'}}>

        <div class="signin-box">
            <div class="tagline">hey there, welcome to</div>
            <div class="signin-title">
                <img 
                    src={logo}    
                    style={{
                        width: "80%"
                    }}  
                />
            </div>
            {/* SPOTIFY BUTTON */}
            <a 
                class="signin-button" 
                onClick={() => setType(true)} // this doesnt work
                href={clicked} 
                style={{backgroundColor: "#1ED760"}}
            >
                <div class="text">
                    continue with
                </div>
                <img 
                    src={SpotifyLogo} 
                    class="logo"        
                />
            </a>
            {/* APPLE MUSIC BUTTON */}
            <a 
                class="signin-button" 
                onClick={() => setType(false)} // this doesnt work
                href={"#"} 
                style={{color: "white", backgroundColor: "#F94C57"}}
            >
                <div class="text">
                    continue with
                </div>
                <img 
                    src={AppleLogo}
                    style={{height: "15px"}}
                    class="logo"        
                />
            </a>
            <a
             class="signin-button" 
             onClick={() => setType(false)}
             style={{color: "white", backgroundColor: "#1C1C1C", border: "1px solid white"}}

            >
                <div class="text">
                    neither, i'd just like to browse
                </div>
            </a>
        </div>

        <div class="cover" />

        </div>
    );
  }
  
  export default Signin;