import React from 'react';
import './About.css';

import { BsLinkedin, BsGithub } from 'react-icons/bs';
import { MdMail } from 'react-icons/md';
import logo from '../assets/logo_v2.png';

var windowWidth = window.innerWidth

console.log(windowWidth)
function About({ showAbout }) {
    return (
        
        <div class="screen">
        <div class="title" onClick={() => showAbout(false)} style={{cursor: 'pointer'}}><img width={
            
            windowWidth < 1000 ? "30%" : "15%"

        } src={logo}/></div>

        <div class="about-title">Meet the team!</div>
        
        <div class="about-card-box">

            <div class="about-card">
                <div class="about-picture">
                    <img class="about-picture" src={"https://media-exp1.licdn.com/dms/image/C4E03AQHSIdPhuWqZiA/profile-displayphoto-shrink_800_800/0/1590078178700?e=1650499200&v=beta&t=5rkFcnJgDEdvKcw88R7HRW58L_oo2igGOw2ePF2eS9M"}/>
                </div>
                <div class="about-name">Ben Minor</div>
                <div class="about-bio">4th year computing student @ Queen's University</div>
                <div class="about-links">
                    <a href="https://www.linkedin.com/in/ben-minor/" target="_blank" style={{color: 'black'}}><BsLinkedin size={30} class="about-icon"/></a>
                    <a href="https://github.com/benminor" target="_blank" style={{color: 'black'}}><BsGithub size={30} class="about-icon"/></a>
                </div>
            </div>

            <div class="about-card">
                <div class="about-picture">
                    <img class="about-picture" src={"https://media-exp1.licdn.com/dms/image/C4D03AQHU3breVJ_JJg/profile-displayphoto-shrink_800_800/0/1641764343997?e=1650499200&v=beta&t=odi39LBE-0S16VGZagiUZ0k50nmB-gncMxJhBE8b9_8"}/>
                </div>
                <div class="about-name">Graham Carkner</div>
                <div class="about-bio">3rd year computing student @ Queen's University</div>
                <div class="about-links">
                    <a href="https://www.linkedin.com/in/gcarkner/" target="_blank" style={{color: 'black'}}><BsLinkedin size={30} class="about-icon"/></a>
                    <a href="https://github.com/GeeCracker" target="_blank" style={{color: 'black'}}><BsGithub size={30} class="about-icon"/></a>
                </div>
            </div>

            <div class="about-card">
                <div class="about-picture">
                    <img class="about-picture" src={"https://media-exp1.licdn.com/dms/image/C4E03AQE10ah2-LSaJw/profile-displayphoto-shrink_800_800/0/1597370359765?e=1650499200&v=beta&t=tPEbzH05qmRLFJaIduNBVoTsipyzLNnCtNdQ2KxdA0M"}/>
                </div>
                <div class="about-name">Cameron Jedemann</div>
                <div class="about-bio">4th year life sciences student @ Queen's University</div>
                <div class="about-links">
                    <a href="https://www.linkedin.com/in/cameronjedemann/" target="_blank" style={{color: 'black'}}><BsLinkedin size={30} class="about-icon"/></a>
                </div>
            </div>

           

        </div>
        </div>
    );
  }
  
  export default About;