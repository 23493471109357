import * as jwt from 'jsonwebtoken';
import axios from 'axios';
import { getDatabase, ref, set } from "firebase/database";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyC8q4KWo5_zDyX_4ZhYt5oktbj67wawTLY",
  authDomain: "qhacks-9b14d.firebaseapp.com",
  databaseURL: "https://qhacks-9b14d-default-rtdb.firebaseio.com",
  projectId: "qhacks-9b14d",
  storageBucket: "qhacks-9b14d.appspot.com",
  messagingSenderId: "407275551530",
  appId: "1:407275551530:web:8a27b960c7eac635387c53",
  measurementId: "G-7KSLE7KM0R"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const analytics = getAnalytics();

const s = process.env.REACT_APP_SECRET_DEVELOPER_KEY    
    
const teamId = "4872KQWSF9";
const keyId = "B8Z36B6763";

const jwtToken = jwt.sign({}, s, {
  algorithm: "ES256",
  expiresIn: "180d",
  issuer: teamId,
  header: {
    alg: "ES256",
    kid: keyId
  }
});

function replace_location(location) {
  var chicago_replacements = ["Illinois", "Wisconsin", "Indiana", "Minnesota"]
  var detroit_replacemenets = ["Michigan", "Ohio"]
  var new_york_replacemenents = ["Pennsylvania", "Virginia", "Maine", "New Jersey"]
  var boston_replacemenets = ["Massachusetts", "Connecticut", "Rhode Island", "Vermont", "New Hampshire"]
  var miami_replacements = ["Florida", "Alabama"]
  var atlanta_replacements = ["Georgia", "Tennessee", "South Carolina", "North Carolina"]
  var houston_replacements = ["Louisiana", "Arkansas", "Oklahoma", "Texas"]
  var phoenix_replacements = ["Arizona", "New Mexico", "Nevada"]
  var denver_replacements = ["Colorado", "Utah", "Wyoming", "Montana", "Nebraska"]
  var seattle_replacements = ["Washington", "Idaho", "Oregon"]
  var midwest_replacements = ["Missouri", "Iowa", "Kansas", "Kentucky", "South Dakota", "North Dakota"]
  var washingtonDC_replacements = ["Maryland", "Delaware", "West Virginia"]
  var los_angeles_replacements = ["California"]
  var toronto_replacements = ["Ontario"]
  var winnipeg_replacements = ["Manitoba"]
  var calgary_replacements = ["Alberta", "Saskatchewan"]
  var vancouver_replacemenets = ["British Columbia"]

  var ocean_replacements = ["????"]


  location = location.split(",")[0]

    if (location === "Florida") {
      location = "Miami"
    }

    else if (chicago_replacements.includes(location)) {
      location = "chicago"
    }

    else if (detroit_replacemenets.includes(location)) {
      location = "detroit"
    }

    else if (new_york_replacemenents.includes(location)) {
      location = "New York"
    }

    else if (boston_replacemenets.includes(location)) {
      location = "boston"
    }

    else if (miami_replacements.includes(location)) {
      location = "miami"
    }

    else if (atlanta_replacements.includes(location)) {
      location = "atlanta"
    }

    else if (houston_replacements.includes(location)) {
      location = "houston"
    }
    else if (phoenix_replacements.includes(location)) {
      location = "phoenix"
    }
    else if (denver_replacements.includes(location)) {
      location = "denver"
    }
    else if (seattle_replacements.includes(location)) {
      location = "seattle"
    }
    else if (midwest_replacements.includes(location)) {
      location = "mid west"
    }
    else if (washingtonDC_replacements.includes(location)) {
      location = "washington DC"
    }
    else if (los_angeles_replacements.includes(location)) {
      location = "los angeles"
    }
    else if (toronto_replacements.includes(location)) {
      location = "toronto"
    }
    else if (winnipeg_replacements.includes(location)) {
      location = "winnipeg"
    }
    else if (calgary_replacements.includes(location)) {
      location = "calgary"
    }
    else if (vancouver_replacemenets.includes(location)) {
      location = "vancouver"
    }
    else if (ocean_replacements.includes(location)) {
      location = "ocean sound"
    }

    return location


}

async function getAppleMusicSongs(passed_location, error) {
  var location = replace_location(passed_location)

  logEvent(analytics, 'select_content', {
    content_type: 'apple',
    location: location
  });

  try {
    

    const data = await axios.get("https://api.music.apple.com/v1/catalog/CA/search?types=playlists&term=" + location, {
      headers: {
          Authorization: `Bearer ${jwtToken}`
      },
    })
  
  
    var playlist_id = (data.data.results.playlists.data[0].id)
    const data2 = await axios.get("https://api.music.apple.com/v1/catalog/CA/playlists/" + playlist_id + "/tracks", {
      headers: {
          Authorization: `Bearer ${jwtToken}`
      },
    })
  
    var final_data = (data2.data.data.splice(0,10))
    return final_data
  }
  catch(e) {

    if (e.name != "TypeError"){
      error(true)
     // Logging Errors
      var today = new Date().toLocaleString()
      var newDate = today.replaceAll('/', '-');    

      set(ref(database, 'errors/' + newDate), {
        error : e.toLocaleString(),
        location: location
      });
    }
    return []
  }
 

}

async function getAppleMusicPodcasts(location, error){
  location = location.split(",")[0]

     
  try{

    const data = await axios.get("https://itunes.apple.com/search?media=podcast&entity=podcast&term=" + location, {
      headers: {
          Authorization: `Bearer ${jwtToken}`
      },
    })

    return(data.data.results.splice(0,10))

  }
  catch(e) {

    if (e.name != "TypeError"){
      error(true)
     // Logging Errors
      var today = new Date().toLocaleString()
      var newDate = today.replaceAll('/', '-');    

      set(ref(database, 'errors/' + newDate), {
        error : e.toLocaleString(),
        location: location
      });
    }
    return []
  }

}

export {getAppleMusicSongs, getAppleMusicPodcasts}