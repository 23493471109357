import React from 'react';
import './Signin.css'


function Error() {

    return (
        <div style={{position: 'fixed', width: '100vw', height: '100vh'}}>

        <div 
            class="signin-box"
            style={{
                marginTop: "35vh",
                paddingBottom: "20px",
            }}
        >
            <div class="tagline">Something went wrong!</div>
            <div class="tagline">We've logged the error and will try to fix it as soon as possible!</div>
            <div class="tagline">In the meantime, please <b>refresh the page</b> to continue :)</div>
        </div>

        <div class="cover" />

        </div>
    );
  }
  
  export default Error;