import React, { useState } from 'react';
import './Pod.css'

import SpotifyLogo from '../assets/Spotify_logo.svg';
import AppleLogo from '../assets/Apple_Music_logo_red.svg'


function Pod({ num, title, link, album, type }) {

    const [hovered, setHovered] = useState();

    return (
        <a href={link} target="_blank">
        <div 
            class="track-box"
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            style={hovered ? {
                marginLeft: "10px",
                borderColor: type == "Spotify" ? "#1DB954" : "#F94C57"
            } : null}
        >
            <div class="track-num">{num+1}.</div>
            <img class="track-album" src={album} />
            <div class="track-info-box">
                <div class="pod-title">{title}</div>
            </div>
            <div class="icon">
            <a 
            href={link}
            target="_blank">
            <img 
                src={type == "Spotify" ? SpotifyLogo : AppleLogo} 
                class="logo"        
            />
            </a> 
            </div>
        </div>
        </a>
    );
  }
  
  export default Pod;