import React, { useEffect, useState, useCallback } from 'react';
import './Mapbox.css'
//import ReactMapboxGl, { Layer, Marker, Feature } from "react-mapbox-gl";
import 'mapbox-gl/dist/mapbox-gl.css';
import Icon from './marker_v2.png';
import axios from 'axios';

import Map, {Marker, NavigationControl} from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import {MarkerDragEvent, LngLat} from 'react-map-gl';
import Pin from './Pin';
import Track from './Track';
import ReactGA from 'react-ga';
const TRACKING_ID = "G-MXS0XFJ6V6"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function Mapbox(props) {

    const { innerWidth: width, innerHeight: height } = window;

    const [coords, setCoords] = useState([0, 53]);
    const [zoom, setZoom] = useState([2.9]);
    const [lat, setLat] = useState(52);
    const [lng, setLng] = useState(0);
    const [map_lat, setMapLat] = useState(0);
    const [map_lng, setMapLng] = useState(50);
    const MAPBOX_TOKEN = "pk.eyJ1IjoiYmVubWlub3IiLCJhIjoiY2t5ejcwY3Y3MGd5NzJucDl3a2NjeWNpNyJ9.WXjH__fEWZQSDQV_VxwjoQ"
    const image = new Image(30, 30);
    image.src = Icon;
    const images = ['myImage', image];

    const initialViewState = {
        latitude: lat,
        longitude: lng,
        zoom: 2.9
      };
   
      async function _onClickMap(evt){
     
        var lng = evt.lngLat.lng
        var lat = evt.lngLat.lat

        setLat(lat)
        setLng(lng)
      
        setZoom([2.9])

        const data = await axios.get("https://api.mapbox.com/geocoding/v5/mapbox.places/" + lng +"," +lat+".json?" +
        "access_token=pk.eyJ1IjoiYmVubWlub3IiLCJhIjoiY2t5ejcwY3Y3MGd5NzJucDl3a2NjeWNpNyJ9.WXjH__fEWZQSDQV_VxwjoQ",{
        })
        var temp_data = (data.data.features)
        var country = "??"
        var place = "??"
        var result = "????"

        for (var x in temp_data){
            if (temp_data[x].id.includes("country")){
                country = temp_data[x].place_name
            }
            result = country
        }

        if (country == "Canada" || country == "United States"){
            for (var x in temp_data){
                if (temp_data[x].id.includes("region")){
                    place = temp_data[x].place_name
                }
            }

            result = place

        }

        ReactGA.event({
            category: 'Location',
            value: result
          });

        props.onDrag(result, props.useSpotify, props.token);
        
    
        
      }


 
      const onMarkerDragEnd = async(e) => {
        var lng = e.lngLat.lng
        var lat = e.lngLat.lat
        setLat(lat)
        setLng(lng)
        setZoom([2.9])

        const data = await axios.get("https://api.mapbox.com/geocoding/v5/mapbox.places/" + lng +"," +lat+".json?" +
        "access_token=pk.eyJ1IjoiYmVubWlub3IiLCJhIjoiY2t5ejcwY3Y3MGd5NzJucDl3a2NjeWNpNyJ9.WXjH__fEWZQSDQV_VxwjoQ",{
        })
        var temp_data = (data.data.features)
        var country = "??"
        var place = "??"
        var result = "????"

        for (var x in temp_data){
            if (temp_data[x].id.includes("country")){
                country = temp_data[x].place_name
            }
            result = country
        }

        if (country == "Canada" || country == "United States"){
            for (var x in temp_data){
                if (temp_data[x].id.includes("region")){
                    place = temp_data[x].place_name
                }
            }

            result = place

        }

        ReactGA.event({
            category: 'Location',
            value: result
          });
    
        props.onDrag(result, props.token);
        


      }


    return (
        <div class="mapbox" >

    <Map
        //initialViewState={initialViewState}
        mapStyle="mapbox://styles/benminor/ckzi1oqkw000915pmlx5njp5p"
        mapboxAccessToken={MAPBOX_TOKEN}
        onClick={e => _onClickMap(e)}
        style={{  height: "100%",
        width: width < 1000 ? "100vw" : "60vw",}}
        center={[52, 0]}
        //zoom={zoom}
  
   
      
      >
        <Marker
          longitude={lng}
          latitude={lat}
          anchor="bottom"
          draggable
          onDragEnd={onMarkerDragEnd}
        >
            <img src={Icon} width={25}/>
        </Marker>

        <NavigationControl />
      </Map>
        </div>
    );
  }
  
  export default Mapbox;