import axios from 'axios';
import { getDatabase, ref, set } from "firebase/database";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
const firebaseConfig = {
  apiKey: "AIzaSyC8q4KWo5_zDyX_4ZhYt5oktbj67wawTLY",
  authDomain: "qhacks-9b14d.firebaseapp.com",
  databaseURL: "https://qhacks-9b14d-default-rtdb.firebaseio.com",
  projectId: "qhacks-9b14d",
  storageBucket: "qhacks-9b14d.appspot.com",
  messagingSenderId: "407275551530",
  appId: "1:407275551530:web:8a27b960c7eac635387c53",
  measurementId: "G-7KSLE7KM0R"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const analytics = getAnalytics();

const searchSpotifySongs = async (location, myToken, error) => {


    var type = "playlist"
      
    logEvent(analytics, 'select_content', {
      content_type: 'spotify',
      location: location
    });

    // Error handling and Easter Eggs
    if (location == "????"){
      location = "ocean sounds"
      type = "playlist"
    }

    else if(location=="Antarctica"){
      location = "penguin sounds"
      type = "playlist"
    }

    else if(location=="Greenland"){
      location = "blizzard noise"
    }

    else if (location == "Nunavut, Canada" || location == "Northwest Territories, Canada"){
      location = "arctic sounds"
    }

    else {
      location = location.split(",")[0] + " top"
      type = "playlist"

    }
   
    try {

      const data = await axios.get("https://api.spotify.com/v1/search", {
          headers: {
              Authorization: `Bearer ${myToken}`
          },
          params: {
              q: location,
              type: type
          }
      })
    
      
      var url_temp =  (data.data.playlists.items[0]['href'])

      return url_temp;

      //getPodcasts(location, myToken)
      //getData(url_temp, myToken)

    }
    catch(e){
      var url_temp = ""

      // no songs found error
      if (e.name != "TypeError"){
        error(true)
       // Logging Errors
        var today = new Date().toLocaleString()
        var newDate = today.replaceAll('/', '-');    
  
        set(ref(database, 'errors/' + newDate), {
          error : e.toLocaleString(),
          location: location
        });
      }

      return url_temp

    }
   
}

const getSpotifySongData = async (query, myToken, error) => {

  try{
    const data_2 = await axios.get(query, {
    headers: {
        Authorization: `Bearer ${myToken}`
    },
    params: {}
    
    })
    var temp_data = (data_2.data.tracks.items).splice(0,10)
    var temp_arr = []


    for (var x of temp_data){
        temp_arr.push(x.track)
    }
    return temp_arr
  }
  catch(e) {

    // no songs found error
    if (!e.message.includes("config url is not valid")) {
      error(true)
    
      var today = new Date().toLocaleString()
      var newDate = today.replaceAll('/', '-');    

      set(ref(database, 'errors/' + newDate), {
        error : e.toLocaleString(),
      });
    }
    return []
  }

  }

const searchSpotifyPodcasts = async (location, myToken, error) => {
  try {
    const data = await axios.get("https://api.spotify.com/v1/search", {
      headers: {
          Authorization: `Bearer ${myToken}`
      },
      params: {
          q: location,
          type: "episode"
      }
    })

    var result = data.data.episodes.items.splice(0,10);

    return result
  }
  catch(e) {

    // no songs found error
    if (e.name != "TypeError"){
      error(true)
     // Logging Errors
      var today = new Date().toLocaleString()
      var newDate = today.replaceAll('/', '-');    

      set(ref(database, 'errors/' + newDate), {
        error : e.toLocaleString(),
        location: location
      });
    }
    return []
  }

  }



  export {searchSpotifySongs, getSpotifySongData, searchSpotifyPodcasts};