import React from 'react';
import './Bar.css'

import { RiHeartFill } from 'react-icons/ri';

function Bar({ about, showAbout, logout }) {

    return (
        <div class="bottom-bar">
     

            <div 
                onClick={() => showAbout(!about)} 
                class="bottom-text"
                style={about ? {fontWeight: 700} : null}
            >
                { about ? <div>home</div>: <div>about</div> }
            </div>
            

            <strong>hello@charts.global</strong>
            <div 
                class="bottom-text-left"
                onClick={() => logout()}
            >
                logout
            </div>
        </div>
    );
  }
  
  export default Bar;